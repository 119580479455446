.button {
    padding: 5px;
    border: 1px solid var(--c-text);
    background: var(--c-background);
    border-radius: 5px;
    min-width: 100px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: color 200ms, background 200ms;
}

.button:hover {
    background: var(--c-text);
    color: var(--c-background);
}

.isInCart {
    transition: none;
    cursor: default;
    pointer-events: none;
}

.text {
    text-align: center;
}
