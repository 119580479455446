:root {
    --c-text: #2e2d2b;
    --c-background: #ffffff;
    --c-accent: #ceb0ae;
    --c-complementary: #68796e;
    --b-tablet: 500px;
    --b-desktop: 800px;
}

html,
body,
#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

li {
    margin-bottom: 8px;
}

body {
    line-height: 1.4;
    overflow-x: hidden;
    margin: 0;
    color: var(--c-text);
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--c-text);
}

h2 {
    margin-block-start: 0;
}

#content {
    flex: 1 0 auto;
    background-color: var(--c-background);
}

.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
}

.easypack-widget {
    height: auto;
    margin-bottom: 20px;
}
