.wrapper {
  padding: 10px 20px 20px;
  position: fixed;
  height: calc(100vh - 30px);
  width: 300px;
  top: 0;
  right: 0;
  background: var(--c-background);
  transform: translateX(100%);
  transition: transform 200ms;
  overflow-y: scroll;
}

.open .wrapper {
  transform: none;
}

.toggle {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--c-background);
  padding: 10px;
  cursor: pointer;
  border-radius: 0 0 0 5px;
  transition: right 200ms;
}

.open .toggle {
  right: 340px;
}

.header {
  margin: 0;
  line-height: 1;
}

.list {
  margin: 0;
  padding: 0;
  margin-block-start: 16px;
  margin-block-end: 16px;
}

.item {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.image {
  width: 50px;
}

.removeButton {
  cursor: pointer;
}

.payButton {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: var(--c-accent);
  color: var(--c-background);
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
}

.payButton[disabled] {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--c-text);
  opacity: 0;
  transition: opacity 200ms;
  pointer-events: none;
}

.open .backdrop {
  opacity: 0.85;
  pointer-events: all;
}

.phoneInput {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
}
