.wrapper {
    display: flex;
    flex-direction: column;
}

.images {
    flex-grow: 1;
}

.image {
    width: 100%;
}

@media (min-width: 800px) {
    .wrapper {
        flex-direction: row;
    }

    .description {
        width: 250px;
        margin-left: 20px;
        flex-shrink: 0;
    }
}
