.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
}

@media (min-width: 500px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 800px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
