.wrapper {
    background: var(--c-text);
    display: flex;
    justify-content: center;
}

.link {
    display: inline-block;
}

.logo {
    width: 45px;
}
