.wrapper {
    text-align: center;
    background: var(--c-text);
    padding: 10px 0;
    line-height: 1;
    flex-shrink: 0;
}

.link {
    display: inline-block;
    color: white;
    margin: 5px;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}
